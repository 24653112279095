.referral .text-banner {
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(255, 122, 0, 0.75);
}
.referral .text-banner-mobile-container {
  border-top: 2px solid #ffdf80;
  border-left: 1px solid #ffdf80;
  border-right: 1px solid #ffdf80;
  border-radius: 16px;
  padding: 10px;
}
.referral .text-banner-secondary {
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  color: #ffdf80;
  text-shadow: 0px 0px 10px rgba(255, 122, 0, 0.75);
}
.referral .text-banner-container {
  left: 8%;
  top: 6%;
}
.referral .prize-container {
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7.5px);
  padding: 3% 5%;
  max-width: 1000px;
  width: 60%;
  /* border-color: linear-gradient(180deg, #ffdf80 0%, rgba(255, 223, 128, 0) 100%) 1; */
  border-top: 5px solid #ffdf80;
  border-left: 2px solid #ffdf80;
  border-right: 2px solid #ffdf80;
  /* color: linear-gradient(180deg, #ffdf80 0%, rgba(255, 223, 128, 0) 100%); */
  color: #ffdf80;
}
/* .referral .prize-container::after {
  background: linear-gradient(180deg, #ffdf80 0%, rgba(255, 223, 128, 0) 100%) 1;
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  content: "";
  z-index: -1;
  border-radius: 30px;
} */
.referral .hr {
  border-right: 3px solid #ffdf80;
  height: 45px;
  align-self: center;
  place-self: center;
}

.referral .text-rank {
  color: #7d121a;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
}
.referral .text-rank-sm {
  color: #7d121a;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
}
.referral .text-milestone {
  font-weight: 900;
  line-height: 38px;
}
.referral .text-prize {
  font-style: normal;
  font-weight: 900;
  line-height: 54px;
  text-align: center;
  color: #7d121a;
  text-shadow: 2px 0 0 #ffffff, -2px 0 0 #ffffff, 0 2px 0 #ffffff,
    0 -2px 0 #ffffff, 1px 1px #ffffff, -1px -1px 0 #ffffff, 1px -1px 0 #ffffff,
    -1px 1px 0 #ffffff;
}
.referral .text-prize-sm {
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  text-align: center;
  color: #7d121a;
  text-shadow: 1px 0 0 #ffffff, -1px 0 0 #ffffff, 0 1px 0 #ffffff,
    0 -1px 0 #ffffff, 1px 1px #ffffff, -1px -1px 0 #ffffff, 1px -1px 0 #ffffff,
    -1px 1px 0 #ffffff;
}
.referral .text-top {
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 33px;
  text-align: center;
  color: #7d121a;
}

.referral .start-container {
  background-color: #fff9e6;
  border: 3px solid #4c2525;
  border-radius: 20px;
}
.referral .border-brown {
  border: 3px solid #4c2525;
  border-radius: 20px;
}
.referral .silver-container {
  background-color: #ffecb3;
  border: 3px solid #4c2525;
  border-radius: 20px;
}
.referral .golden-container {
  background-color: #ffe599;
  border: 3px solid #4c2525;
  border-radius: 20px;
}
.referral .bronze-container {
  background-color: #fff2cc;
  border: 3px solid #4c2525;
  border-radius: 20px;
}
.referral .platium-container {
  background-color: #ffd865;
  border: 3px solid #4c2525;
  border-radius: 20px;
}
.referral .rank-container {
  border-bottom: 1px solid #757575;
}

.referral .center-div {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.referral .tab_active {
  background: #ffdf80;
}

.referral .tab-disabled {
  background: #ffdf80;
  opacity: 0.5;
}

.referral .header {
  font-family: "CookieRunRegular";
  font-style: normal;
  font-weight: 700;
  align-items: center;
  text-align: center;
  color: #7d121a;
}

.referral .hr-tab {
  border: 1px solid black;
  width: 1px;
  text-align: center;
  justify-content: center;
  justify-self: center;
}
.referral .referral-table td {
  text-align: center;
}
.referral .referral-table thead {
  background-color: #ffdf80 !important;
  text-align: center;
}
.referral .referral-table tr th {
  background-color: #ffe38c !important;
  text-align: center;
}

.referral .referral-table {
  background-color: #ffdf80;
}

.referral .text-link {
  cursor: pointer;
  color: rgb(53, 53, 220);
}
.referral .flex-list {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}
.react-button {
  transition-duration: 0.3s;
}
.react-button:hover {
  filter: brightness(1.5);
}
.react-button:active {
  filter: brightness(0.8);
}
.react-text {
  color: rgba(255, 255, 255, 0.6);
  transition-duration: 0.3s;
}
.react-text:hover {
  color: white;
}
.react-text:active {
  color: rgba(255, 255, 255, 0.2);
}

.disabled-event {
  opacity: 0.6;
}
.disabled-event .rank-container-wrap {
  background: #B7B7B7;
}
.disabled-event-laptop {
  background: #B7B7B7 !important;
  opacity: 0.6;
}
.active-leaderboard {
  
}