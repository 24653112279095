.ticket-container:hover .ticket-button {
  display: flex;
}

.ticket-button {
  display: none;
}

.ticket-container:hover .ticket-quantity {
  display: none;
}

.ticket-container:hover .card-header,
.ticket-container:hover img {
  /* z-index: -1; */
  opacity: 0.75;
}

.ticket-quantity {
  display: block;
  color: #ebc276;
}

.dao-container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 6.25vw;
  padding-right: 6.25vw;
}

.dao-container .font-cookie-run {
  color: #ebc276;
}

.bg-reward {
  background-color: #695858;
}