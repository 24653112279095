.center-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-countdown-event {
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  color: #ffdf80;
  text-shadow: 0px 0px 10px rgba(255, 122, 0, 0.75);
}

.text-number-event-chaos {
  text-shadow: -2px -2px 0 #42210b, 0 -2px 0 #42210b, 2px -2px 0 #42210b,
    2px 0 0 #42210b, 2px 2px 0 #42210b, 0 2px 0 #42210b, -2px 2px 0 #42210b,
    -2px 0 0 #42210b;
  font-family: "CookieRunRegular";
  font-style: normal;
  font-weight: 700;
  -webkit-text-fill-color: white;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.anim-box {
  animation: shake 3.5s;
  animation-iteration-count: infinite;
}

.mx-unset {
  margin: unset !important;
}

.text-top-ten {
  background: linear-gradient(177.25deg, #ffea0e 17.88%, #ef8828 97.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes pulse {
  0% {
    box-shadow: inset 0px 0px 10px 15px #d0c2c2, 0px 0px 10px 8px #d0c2c2;
    border-radius: 100px;
    border-color: #d0c2c2 solid 1px;
  }
  25% {
    box-shadow: inset 0px 0px 10px 15px #71ad64, 0px 0px 20px 10px #71ad64;
    border-radius: 100px;
    border-color: #71ad64 solid 1px;
  }
  50% {
    box-shadow: inset 0px 0px 10px 15px #7d8de9, 0px 0px 10px 8px #7d8de9;
    border-radius: 100px;
    border-color: #7d8de9 solid 1px;
  }
  75% {
    box-shadow: inset 0px 0px 10px 15px #d98ffa, 0px 0px 20px 10px #d98ffa;
    border-radius: 100px;
    border-color: #d98ffa solid 1px;
  }
  100% {
    box-shadow: inset 0px 0px 10px 15px #d0c2c2, 0px 0px 10px 8px #d0c2c2;
    border-radius: 100px;
    border-color: #d0c2c2 solid 1px;
  }
}

.glow {
  animation: pulse 5s infinite alternate;
}

@keyframes box-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes box-fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 1;
    transform: scale(0.75);
    display: none;
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
    display: none;
  }
}
.giftbox-0 {
}
.giftbox-1 {
  animation: box-fade-out 2s ease-in-out;
  animation-iteration-count: 1;
  opacity: 0;
}
.giftbox-2 {
  opacity: 0;
  display: hidden;
}

.openedbox-0 {
  opacity: 0;
  display: hidden;
}
.openedbox-1 {
  animation: box-fade-in 2s ease-in-out;
  animation-iteration-count: 1;
  opacity: 1;
}
.openedbox-2 {
  opacity: 0;
  display: hidden;
}
@keyframes box-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.mongen-0 {
  opacity: 0;
  display: hidden;
}
.mongen-1 {
  opacity: 0;
}
.mongen-2 {
  animation: mongen-fade-in 4s ease-in-out;
  animation-iteration-count: 1;
}

.chaos-card:hover {
  box-shadow: inset 0px 4px 100px 1px #a2d2ff, 0px 4px 20px 3px #e1c42d;
  /* box-shadow: inset 0px 4px 100px 1px #9ECEFF, 0px 4px 20px 3px #E1C42D; */
  border-radius: 10px;
  border-color: solid transparent 1px;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
    box-shadow: inset 0px 4px 100px 1px #a2d2ff, 0px 4px 20px 3px #e1c42d;
    transform: translatey(0px);
  }
  50% {
    box-shadow: inset 0px 24px 100px 1px #a2d2ff, 0px 4px 20px 3px #e1c42d;
    transform: translatey(-20px);
  }
  100% {
    box-shadow: inset 0px 4px 100px 1px #a2d2ff, 0px 4px 20px 3px #e1c42d;
    transform: translatey(0px);
  }
}

@media only screen and (max-width: 500px) {
  .force-canvas .spine-player .spine-player-canvas {
    width: 300px;
    height: 300px;
  }
  .force-canvas div {
    width: 300px !important;
    height: 300px !important;
  }
}
