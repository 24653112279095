@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PoppinsMedium";
  src: local("Popins"),
    url("/public/assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsLight";
  src: local("Popins"),
    url("/public/assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  src: local("Popins"),
    url("/public/assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RammettoOne";
  src: local("Rametto"),
    url("/public/assets/fonts/RammettoOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CookieRunRegular";
  src: local("CookieRun"),
    url("/public/assets/fonts/CookieRun-Regular.otf") format("opentype");
}

@font-face {
  font-family: "CookieRunBold";
  src: local("CookieRunBold"),
    url("/public/assets/fonts/CookieRun_Bold.otf") format("opentype");
}

@font-face {
  font-family: "CookieRunBlack";
  src: local("CookieRunBlack"),
    url("/public/assets/fonts/CookieRun_Black.otf") format("opentype");
}

div,
span {
  font-family: "Poppins";
}

.font-cookie-bold {
  font-family: "CookieRunBold" !important;
}

.font-cookie-black {
  font-family: "CookieRunBlack" !important;
}

.font-cookie {
  font-family: "CookieRunRegular" !important;
}

.font-cookie-run {
  font-family: "CookieRunRegular";
}

body {
  background-color: #f3f2f6;
  height: min-content;
}

.unselectable {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}

.input-disabled {
  cursor: not-allowed;
}

.main-header {
  position: fixed;
  z-index: 99;
  top: 0;
  /* height: 73px; */
}

.logo-header img {
  width: 105.62px !important;
}

.bg-yellow-linear {
  background: linear-gradient(180deg, #f9c744, #df8c00);
}

.bg-black-linear {
  background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
}

.bg-black-linear-secondary {
  background: linear-gradient(180deg, #31313d 0%, #181718 100%);
}

.bg-black-linear-3 {
  background: linear-gradient(0deg, #25252c, #25252c);
}

.bg-black-linear-4 {
  background: linear-gradient(0deg, #2d3439, #2d3439);
}

.bg-black-primary {
  background: #1c1b1e;
}

.bg-black-secondary {
  background: #1c2023 !important;
}

.bg-black-secondary a {
  color: white !important;
  border-color: white;
}

.bg-black-3 {
  background: #1c1914 !important;
}

.border-grey-primary {
  border-color: #2e303e;
}

.vr {
  border: 1px solid #2e303e;
  transform: rotate(90deg);
}

.br {
  border: 1px solid #2e303e;
}

.br-2 {
  border: 2px solid #2e303e;
  background-color: #2e303e;
}

.bg-orange-wallet {
  background: #f6851b;
}

.bg-blue-wallet {
  background: #5493f7;
}

.bg-purple-wallet {
  background: #5332d1;
}

.bg-red-wallet {
  background: #c52627;
}

.bg-green-primary {
  background: #234224;
}

.bg-red-primary {
  background: #8f2929;
}

.text-white-light {
  color: #e1e2e9;
}

.text-white-light-f {
  color: #e1e2e9 !important;
}

.text-orange {
  color: #e89f01;
}

.border-orange {
  border-color: #e89f01;
}

.border-gray-2 {
  border-color: #727272;
}

.bg-orange {
  background-color: #e89f01;
}

.border-gray {
  border-color: #2e3438;
}

.text-red {
  color: #f56262;
}

.text-gray {
  color: #6c6d76;
}

.text-gray-bold {
  color: #a9a9a9;
}

.main-layout::before {
  background-attachment: fixed;
  background-size: cover;
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: -1;
}

.text-uppercase {
  text-transform: uppercase;
}

.truncated-p {
  overflow: hidden;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.h-180 {
  height: 180px;
}

.custom-btn-color-yellow {
  background: #463d24;
  color: #efb12a;
}

.custom-btn-color-red {
  background: #3f282b;
  color: #c24646;
}

.custom-btn-color-gray {
  background: #a3a3a3;
  color: #000000;
}

.custom-btn-color-purple {
  background: #2d2c60;
  color: #9997ff;
}

.custom-btn-color-purple-red {
  background: #312132;
  color: #ef2eb8;
}

.custom-btn-color-orange {
  background: #534735;
  color: #ffae19;
}

.custom-btn-color-blue {
  background: #21323d;
  color: #0bb5fe;
}

.reward-border {
  border-color: #f7c340;
}

.text-border-brown {
  text-shadow: 2px 0 0 #5f3824, -2px 0 0 #5f3824, 0 2px 0 #5f3824,
    0 -2px 0 #5f3824, 1px 1px #5f3824, -1px -1px 0 #5f3824, 1px -1px 0 #5f3824,
    -1px 1px 0 #5f3824;
}

.reward-box-shadow {
  box-shadow: 0px 6px 30px rgba(255, 184, 119, 0.3),
    0px 6px 16px rgba(253, 204, 132, 0.2) !important;
}

.art-btn-yellow-content {
  background: linear-gradient(180deg, #f9c744 0%, #df8c00 100%);
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  /* border-radius: 6px 6px 9px 9px; */
}

.art-btn-yellow-content-reverse {
  background: linear-gradient(180deg, #df8c00 100%, #f9c744 0%);
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  /* border-radius: 6px 6px 9px 9px; */
}

.art-btn-yellow-container {
  background: #ae4b12;
  border: 1px solid #5f3824;
  /* border-radius: 6px; */
}

.art-btn-red-content {
  background: linear-gradient(180deg, #d55050 0%, #a43535 100%);
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  /* border-radius: 6px 6px 9px 9px; */
}

.art-btn-red-container {
  background: #610a0a;
  border: 1px solid #5f3824;
  /* border-radius: 6px; */
}

.art-btn-brown-content {
  background: linear-gradient(180deg, #be9c80 0%, #68492a 100%);
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  /* border-radius: 6px 6px 9px 9px; */
}

.art-btn-brown-container {
  background: #44240e;
  border: 1px solid #714e3c;
  /* border-radius: 6px; */
}

.btn-disabled {
  opacity: 0.4;
}

/* override navbar item */
.ui.inverted.menu .active.item {
  background-color: unset;
  font-weight: 600;
  color: #e89f01 !important;
}

.ui.inverted.menu .active.item:hover {
  background-color: unset;
  color: #e89f01 !important;
}

.ui.inverted.menu .item:not(.hoverless):hover {
  color: #e89f01;
  background-color: unset;
}

.hover-orange:hover {
  color: #e89f01;
}

/* override navbar item */

textarea:focus,
input:focus {
  outline: none;
}

.text-black {
  color: #1a1917;
  line-height: 30px;
}

.main-container {
  margin-left: 6.25vw !important;
  margin-right: 6.25vw !important;
  margin-top: calc(70px + 1.5rem);
  /* header height */
}

.ui.card>.content,
.ui.cards>.card>.content {
  padding: unset;
}

.navbar-md {
  justify-content: center;
}

.border-t-3 {
  border-top-width: 3px;
}

.h-min-32 {
  min-height: 3.5rem;
}

.text-red-2 {
  color: #d55050;
}

.hover-bg-linear-yellow:hover {
  background: #534735;
}

.ui.inverted.table {
  background-color: transparent;
}

.bg-gray {
  background-color: #292929;
  border-color: transparent !important;
}

.bg-gray-header.inverted.table th {
  background-color: #292929;
}

.no-border-header.inverted.table th {
  border-color: transparent !important;
}

.text-yellow {
  color: #ffa919;
}

.ui.table tr:last-child {
  pointer-events: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.ui.padded.table td,
.ui.padded.table th {
  padding: 1em 1.5em;
}

.text-input-disabled {
  background-color: #bcbdc0 !important;
}

.dot {
  height: 3px;
  width: 3px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
}

.ui.selection.dropdown .menu>.item {
  background-color: white;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  border: 1px solid #2185d0;
}

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
  border: 1px solid #2185d0;
}

.link-disabled {
  pointer-events: none;
  color: gray !important;
}

.ui.dimmer {
  /* background-color: unset; */
}

.developing {
  opacity: 0.3;
  pointer-events: none;
}

.developing .opacity-1 {
  opacity: 1 !important;
}

.ui.selection.visible.dropdown>.text:not(.default) {
  color: unset;
}

.ui.pagination.menu {
  background: transparent;
  border: none;
  box-shadow: none;
}

.ui.menu .item:before {
  display: none;
}

.ui.pagination.menu .item,
.ui.pagination.menu .item:last-child {
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  margin-left: 4px;
  margin-right: 4px;
  color: #bcbcbc;
}

.ui.pagination.menu .active.item {
  background-color: #e89f01;
  color: white;
}

.bg-brown {
  background: #24211f;
}

.bg-brown-bold {
  background: #35332f;
}

.border-blue-brown {
  border-color: #1c2023;
}

.bg-black-light {
  background-color: #141416;
}

.ticket-container:hover .ticket-button {
  display: flex;
}

.ticket-button {
  display: none;
}

.ticket-container:hover .ticket-quantity {
  display: none;
}

.ticket-container:hover .card-header,
.ticket-container:hover img {
  /* z-index: -1; */
  opacity: 0.75;
}

.ticket-quantity {
  display: block;
}

.rc-slider-handle {
  cursor: pointer !important;
  transform: translateX(0%) translateY(1px) !important;
  width: 18px !important;
  height: 18px !important;
  z-index: 9;
}

.rc-slider,
.rc-slider-horizontal {
  transform: translateY(7px);
}

.rc-slider-rail {
  height: 8.4px !important;
  border-radius: 0 !important;
  z-index: 9;
  margin-top: 0.4px;
}

.rc-slider-track {
  height: 10px !important;
  border-radius: 0 !important;
  z-index: 9;
}

.rc-slider-rail {
  transform: translateX(18px);
}

.rc-slider-handle {
  border-radius: 10% !important;
  opacity: 1 !important;
}

.rc-slider-handle:hover,
.rc-slider-handle:focus {
  border-width: 1px;
  border-color: #ebc276 !important;
  box-shadow: unset !important;
}

.decrease-switch {
  transform: translateX(4px);
  z-index: 9;
}

.increase-switch {
  transform: translateX(10px);
  z-index: 9;
}

.noHover {
  pointer-events: none !important;
}

.text-green {
  color: #40ca29;
}

.text-orange {
  color: #ffae19;
}

.ui.inverted.menu {
  max-width: 100vw;
}

@media only screen and (max-width: 600px) {
  .ui.bottom.center.popup {
    display: none !important;
  }
}

.ui.inverted.menu {
  max-width: 100vw;
}

@media only screen and (max-width: 600px) {
  .ui.bottom.center.popup {
    display: none !important;
  }
}

.menu-icon {
  border-right: 1px solid #262526;
}

#menu-dropdown {
  margin-left: 12px;
  width: 10px !important;
  height: 6px !important;
}

.menu-icon:hover img {
  filter: invert(57%) sepia(29%) saturate(2462%) hue-rotate(12deg) brightness(103%) contrast(103%);
}

.page-component:hover {
  border-radius: 4px;
  background: #323134;
}

.page-component:hover .text-header {
  color: #e89f01;
}

.page-component .text-header {
  font-family: "CookieRunRegular";
  font-weight: bold;
  color: #e1e2e9;
}

.main-gradient {
  @apply bg-gradient-to-b from-[#F9C744] to-[#DF8C00];
}

.secondary-gradient {
  @apply bg-gradient-to-b from-[#BE9C80] to-[#68492A];
}

.error-gradient {
  @apply bg-gradient-to-b from-[#D55050] to-[#A43535];
}

.react-datepicker__input-container input {
  width: 100%;
}

.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
  opacity: 0.25 !important;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}

.text-number-event {
  text-shadow: -3px -3px 0 #9c460a, 0 -3px 0 #9c460a, 3px -3px 0 #9c460a,
    3px 0 0 #9c460a, 3px 3px 0 #9c460a, 0 3px 0 #9c460a, -3px 3px 0 #9c460a,
    -3px 0 0 #9c460a;
  font-family: "CookieRunRegular";
  font-style: normal;
  font-weight: 700;
  -webkit-text-fill-color: #ffda9f;
}

.text-number-event-2 {
  text-shadow: -1px -1px 0 #9c460a, 0 -1px 0 #9c460a, 1px -1px 0 #9c460a,
    1px 0 0 #9c460a, 1px 1px 0 #9c460a, 0 1px 0 #9c460a, -1px 1px 0 #9c460a,
    -1px 0 0 #9c460a;
  font-family: "CookieRunRegular";
  font-style: normal;
  font-weight: 700;
  -webkit-text-fill-color: #ffeac8;
}

.text-number-event-3 {
  text-shadow: -1px -1px 0 #522410, 0 -1px 0 #522410, 1px -1px 0 #522410,
    1px 0 0 #522410, 1px 1px 0 #522410, 0 1px 0 #522410, -1px 1px 0 #522410,
    -1px 0 0 #522410;
  font-family: "CookieRunRegular";
  font-style: normal;
  font-weight: 700;
  -webkit-text-fill-color: #f5bd63;
}

.text-number-event-4 {
  text-shadow: -1px -1px 0 #522410, 0 -1px 0 #522410, 1px -1px 0 #522410,
    1px 0 0 #522410, 1px 1px 0 #522410, 0 1px 0 #522410, -1px 1px 0 #522410,
    -1px 0 0 #522410;
  font-family: "CookieRunRegular";
  font-style: normal;
  font-weight: 700;
  -webkit-text-fill-color: white;
}

.text-event {
  text-shadow: 0px 4px 0px #5b2303;
  font-family: "CookieRunRegular";
  color: #f1b45c;
}

/* .bg-celest-event {
  background: url("/public/assets/images/brightness-board.png") !important;
  background-size: contain;
} */

/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-800%) translateY(12.5%);
  }
}

.draw-animation-after {
  animation: moveafter 5s linear infinite
    /* infinite make reapeat the animation indefinitely */
  ;
  transform: translateY(12.5%) translateX(800%);
}

/* Create the animation */
@keyframes moveafter {
  to {
    transform: translateX(0%) translateY(12.5%);
  }
}

/* hihi */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 5));
    /* CHANGE HERE */
  }
}

.slider {
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  margin: auto;
  overflow: hidden;
  position: relative;
  width: calc(250px * 5);
}

.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
  background: linear-gradient(to right,
      rgba(0, 0, 0, 0.125) 0%,
      rgba(255, 255, 255, 0) 100%);
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  display: flex;
  width: calc(250px * 10);
  /* CHANGE HERE */
}

.slider .slide {
  height: 250px;
  width: 250px;
  color: white;
}

.draw-animation {
  animation: 10s ease-out 0s 1 scroll, 1s linear 0s 20 scroll;
  /* animation: scroll 1s linear 10; */
}

.draw-animation-mid {
  animation: scroll 1s linear 10;
}

.draw-animation-mid-slow {
  animation: scroll 2s linear 10;
}

.draw-animation-slow {
  animation: scroll 3s ease-out 1;
}

.nft-plot-image {
  background-color: #141416;
  height: 100%;
  width: 180px;
}

.nft-plot-image .plot-img {
  /* width: 120px; */
  height: 120px;
  margin: auto;
  transform: translateY(30px);
}

.nft-troop {
  background-color: #141416;
  padding-top: 100%;
}

.custom-pagination {
  z-index: 99 !important;
  align-self: center;
  justify-items: center;
  min-height: 10px !important;
  display: flex !important;
  justify-content: center;
}

.custom-pagination a {
  color: white !important;
  border-color: white;
  height: 10px;
  padding: 10px !important;
  min-width: unset !important;
}

@keyframes fade-out {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-5deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(5deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(5deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-5deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-5deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(5deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-5deg);
  }

  0% {
    opacity: 1;
    width: 180px;
    height: min-content;
  }

  50% {
    opacity: 1;
    width: 180px;
    height: min-content;
  }

  70% {
    opacity: 1;
    height: min-content;
  }

  100% {
    opacity: 0;
    display: none;
    width: 0px;
    height: 0px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.25);
  }

  80% {
    opacity: 0;
    transform: scale(0.25);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes egg-fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
    width: 180px;
  }

  90% {
    opacity: 1;
    transform: scale(0.5);
    width: 180px;
    display: none;
  }

  100% {
    opacity: 0;
    transform: scale(0.5);
    display: none;
    width: 0px;
  }
}

@keyframes egg-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.egg-disappear {
  animation: egg-fade-out 2s ease-in-out;
  animation-iteration-count: 1;
  opacity: 0;
  width: 0px;
}

.egg-animation {
  animation: fade-out 1s ease-in-out;
  animation-iteration-count: 1;
  opacity: 0;
  width: 0px;
  height: 0px;
}

.mongen-animation {
  animation: fade-in 2s ease-in-out;
  animation-iteration-count: 1;
  opacity: 1;
}

.loader {
  border: 6px solid black;
  border-radius: 50%;
  border-top: 6px solid transparent;
  border-bottom: 6px solid black;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-white {
  border: 6px solid white;
  border-radius: 50%;
  border-top: 6px solid transparent;
  border-bottom: 6px solid white;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-sm {
  border: 3px solid #ebc276;
  border-radius: 50%;
  border-top: 3px solid transparent;
  border-bottom: 3px solid #ebc276;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin-top: 1.5px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.crop {
  background-position: center center;
  background-repeat: no-repeat;
}

.hidden-mongen {
  display: none;
  opacity: 0;
}

@keyframes fade-hidden {
  0% {
    opacity: 100;
    width: 180px;
  }

  50% {
    opacity: 0;
    width: 180px;
  }

  100% {
    opacity: 0;
    width: 0px;
  }
}

.animate-hidden {
  animation: fade-hidden 2s ease-in-out;
  opacity: 0;
  width: 0px;
}

.star-container {
  width: auto;
  padding-right: 17px;
  border-right: 1px solid white;
  margin-right: 14px;
  display: flex;
}

.tab-title {
  margin-bottom: 0.8125rem;
  font-weight: 600;
}

.custom-tab {
  height: 42px;
}

.bottom-line {
  height: 1px;
  background: linear-gradient(90deg,
      rgba(232, 159, 1, 0),
      #e89f01 47.4%,
      rgba(232, 159, 1, 0));
}

.active .tab-title {
  color: #e89f01;
}

.spine-player #spine-player-button-logo {
  display: none;
}

.spine-player-button {
  display: none;
}

.spine-player .spine-player-canvas {
  width: 500px;
  height: 500px;
}

.force-image img {
  width: 80px !important;
  height: 80px !important;
}

.force-image-chaos img {
  width: 120px !important;
  height: 120px !important;
}

.tooltip-skill {
  width: 320px;
  position: absolute;
  background-color: #373d5e;
}

.tooltip-skill .content {
  background-color: #21243a;
}

.tooltip-skill .desc {
  background-color: #171929;
}

.active-skill {
  border: 2px solid #75f94e;
}

.tooltip-top-left {
  transform: translateY(calc(-100% - 100px)) translateX(calc(-100% + 80px));
}

.tooltip-top-right {
  transform: translateY(calc(-100%)) translateX(calc(80px));
}

.select-item {
  border-top: 1px solid black;
  cursor: pointer;
}

.select-item:hover {
  backdrop-filter: brightness(2);
}

.mongen-bought {
  filter: saturate(0%);
}

.custom-checkbox label {
  color: white !important;
  /* background:  #843C00; */
}

.checkbox-container {
  border: 1px solid #5f3824;
  border-radius: 6px;
}

.custom-checkbox .ui.checkbox .box::before,
.ui.checkbox label::before {
  background: #a4410d !important;
}

.text-Epic {
  color: #d98ffa;
}

.text-Uncommon {
  color: #71ad64;
}

.text-Common {
  color: #d0c2c2;
}

.text-Rare {
  color: #7d8de9;
}

.bg-noti {
  /* background: rgba(0, 0, 0, 0.125); */
  text-shadow: 2px 2px #ff0000;
}

#noti-container:hover {
  height: 200px;
}

#noti-container {
  height: 96px;
  border-top-left-radius: 12px;
}

.header-mb {
  width: 100% !important;
  max-width: 100%z !important;
}

.navbar-md {
  justify-content: center;
}

#mbsignin {
  justify-content: right !important;
}

.mb-spin {
  /* transform: scale(0.6); */
}

.laptop-spin {
  /* transform: scale(1);
  top: unset;
  left: unset; */
}

.text-burned {
  color: grey
}

.ant-tabs-tab-active .auction-menu-active {
  background: #372E1C;
  /* opacity: 0.1; */
  color: #E89F01
}

.ant-tabs-left .ant-tabs-nav {
  width: 250px;
  background-color: #24211F;
  min-height: 100vh;
  font-family: "CookieRunRegular";
  font-style: normal;
  font-weight: 400;
  color: white;
  padding-top: 10px;
}

.filter-rarity-auction {
  width: 130px;
  min-width: unset !important;
  background: linear-gradient(180deg, #101014, #181e1d) !important;
  border: 1px solid #2e303e !important;
  box-sizing: border-box;
  border-radius: 6px !important;
  color: white !important
}

@media only screen and (max-width: 639px) {
  .ant-tabs-left .ant-tabs-nav {
    width: 100%;
    height: fit-content;
    min-height: 90px;
    padding-top: 20px
  }

  .drop-down-auction {
    display: inline !important;
  }

  .auction-image .spine-player-canvas {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .drop-down-auction {
    display: none !important;
  }
}

.ant-tabs-left .ant-tabs-content-holder {
  width: 100%;
}

.auction-tab-history .ant-tabs-nav-list {
  display: flex;
}

.auction-tab-history .ant-tabs-nav-list .ant-tabs-tab {
  margin-right: 20px;
  color: #E1E2E9
}

.auction-tab-history .ant-tabs-nav-list .ant-tabs-tab-active {
  color: #E89F01;
}

.auction-tab-history .ant-tabs-nav-list .ant-tabs-tab-active .bottom-line {
  display: block;
}

.auction-item .spine-player .spine-player-canvas {
  width: 100%;
  height: 100%;
}

.auction-item {
  height: fit-content;
}

.ant-tabs-tabpane-hidden {
  display: none;
}

.btn-yellow {
  background: linear-gradient(180deg, #F9C744 0%, #DF8C00 100%);
  box-shadow: 0px 4px #ae4b12;
  border-radius: 6px 6px 9px 9px;
  color: white;

}

.btn-yellow:disabled {
  cursor: not-allowed;
  background: #ccc;
  box-shadow: none
}

.btn-red {
  background: linear-gradient(180deg, #AE4B12 100%, #FF0000 10%);
  box-shadow: 0px 4px #484D09 16%;
  border-radius: 6px 6px 9px 9px;
  color: white;

}

.btn-red:disabled {
  cursor: not-allowed;
  background: #ccc;
  box-shadow: none
}

.input-bid .ant-form-item-explain {
  width: 100%;
  text-align: center;
}

.input-bid .ant-form-item-control-input-content .ant-input {
  width: 100%;
}

.ant-form-item-explain-error {
  color: red;
  font-size: 10px;
}

.list-bidders::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.auction-container {
  height: calc(100vh - 70px) !important;
  overflow: hidden;
}

.auction-list {
  height: calc(100vh - 280px);
  overflow: scroll;
  padding-bottom: 20px;
}

.auction-list::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.auction-paging .ant-pagination-item {
  margin: 10px;
  cursor: pointer;
}

.auction-paging .ant-pagination-item-active {
  color: #E89F01
}

.ant-pagination-item-container .anticon-double-right {
  display: none;

}

.ant-pagination-item-container .anticon-double-left {
  display: none;

}

.ui.menu .dropdown.item .menu.dark-dropdown {
  background-color: #1b1c1d;
}
